var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contentBox"},[_c('section',[_c('div',{staticClass:"titlebg"},[_vm._v(" 建搜搜 ")]),_c('div',{staticClass:"firstResume"},[_c('el-form',{ref:"editWorkInfo",staticStyle:{"padding":"0 80px 0 40px"},attrs:{"model":_vm.editWorkInfo,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"真实姓名","prop":"real_name"}},[_c('el-input',{model:{value:(_vm.editWorkInfo.real_name),callback:function ($$v) {_vm.$set(_vm.editWorkInfo, "real_name", $$v)},expression:"editWorkInfo.real_name"}})],1),_c('el-form-item',{attrs:{"label":"性别","prop":"sex"}},[_c('el-radio-group',{model:{value:(_vm.editWorkInfo.sex),callback:function ($$v) {_vm.$set(_vm.editWorkInfo, "sex", $$v)},expression:"editWorkInfo.sex"}},[_c('el-radio',{attrs:{"label":"1"}},[_vm._v("男")]),_c('el-radio',{attrs:{"label":"2"}},[_vm._v("女")])],1)],1),_c('el-form-item',{staticStyle:{"position":"relative"},attrs:{"label":"持有证书"}},[_c('el-cascader',{staticStyle:{"width":"70%"},attrs:{"options":_vm.selCerList,"props":{
              checkStrictly: true,
              value: 'certificate',
              label: 'certificate',
              children: 'child',
            },"filterable":"","clearable":""},model:{value:(_vm.cerList),callback:function ($$v) {_vm.cerList=$$v},expression:"cerList"}}),_c('el-select',{staticStyle:{"width":"30%"},attrs:{"placeholder":"注册情况"},model:{value:(_vm.editWorkInfo.register),callback:function ($$v) {_vm.$set(_vm.editWorkInfo, "register", $$v)},expression:"editWorkInfo.register"}},[_c('el-option',{attrs:{"label":"初始注册","value":"初始注册"}}),_c('el-option',{attrs:{"label":"转注册","value":"转注册"}}),_c('el-option',{attrs:{"label":"初转不限","value":"初转不限"}})],1)],1),_c('el-form-item',{attrs:{"label":"期望职位"}},[_c('el-cascader',{staticStyle:{"width":"100%"},attrs:{"options":_vm.selJobList,"props":{
              checkStrictly: true,
              value: 'category_name',
              label: 'category_name',
              children: 'child',
            },"filterable":"","clearable":""},model:{value:(_vm.jobList),callback:function ($$v) {_vm.jobList=$$v},expression:"jobList"}})],1),_c('el-form-item',{attrs:{"label":"期望城市"}},[_c('el-cascader',{staticStyle:{"width":"100%"},attrs:{"size":"large","options":_vm.userInfoSocial},on:{"change":_vm.setWorkInfoCity},model:{value:(_vm.expectCity),callback:function ($$v) {_vm.expectCity=$$v},expression:"expectCity"}})],1),_c('el-form-item',{attrs:{"label":"职业描述"}},[_c('el-input',{attrs:{"type":"textarea","rows":3,"placeholder":"可填写招聘需求补充说明，请勿在此填写联系方式，以免被系统锁定账号"},model:{value:(_vm.editWorkInfo.added),callback:function ($$v) {_vm.$set(_vm.editWorkInfo, "added", $$v)},expression:"editWorkInfo.added"}})],1)],1),_c('div',{staticStyle:{"text-align":"center"}},[_c('el-button',{directives:[{name:"prevent-repeat-click",rawName:"v-prevent-repeat-click"}],staticClass:"radius-25",attrs:{"type":"primary"},on:{"click":_vm.editWorkInfoPost}},[_vm._v(" 发布简历 ")])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }