<template>
  <div class="contentBox">
    <section>
      <div class="titlebg">
        建搜搜
      </div>
      <div class="firstResume">
        <el-form
          :model="editWorkInfo"
          ref="editWorkInfo"
          label-width="80px"
          style="padding: 0 80px 0 40px;"
        >
          <el-form-item label="真实姓名" prop="real_name">
            <el-input v-model="editWorkInfo.real_name"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="sex">
            <el-radio-group v-model="editWorkInfo.sex">
              <el-radio label="1">男</el-radio>
              <el-radio label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="持有证书" style="position:relative">
            <el-cascader
              style="width: 70%"
              v-model="cerList"
              :options="selCerList"
              :props="{
                checkStrictly: true,
                value: 'certificate',
                label: 'certificate',
                children: 'child',
              }"
              filterable
              clearable
            >
            </el-cascader>
            <el-select
              v-model="editWorkInfo.register"
              placeholder="注册情况"
              style="width: 30%"
            >
              <el-option label="初始注册" value="初始注册"></el-option>
              <el-option label="转注册" value="转注册"></el-option>
              <el-option label="初转不限" value="初转不限"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="期望职位">
            <el-cascader
              style="width: 100%"
              v-model="jobList"
              :options="selJobList"
              :props="{
                checkStrictly: true,
                value: 'category_name',
                label: 'category_name',
                children: 'child',
              }"
              filterable
              clearable
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="期望城市">
            <el-cascader
              size="large"
              :options="userInfoSocial"
              v-model="expectCity"
              @change="setWorkInfoCity"
              style="width: 100%;"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="职业描述">
            <el-input
              type="textarea"
              :rows="3"
              v-model="editWorkInfo.added"
              placeholder="可填写招聘需求补充说明，请勿在此填写联系方式，以免被系统锁定账号"
            ></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: center">
          <el-button
            type="primary"
            class="radius-25"
            v-prevent-repeat-click
            @click="editWorkInfoPost"
          >
            发布简历
          </el-button>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { provinceAndCityData, CodeToText } from "element-china-area-data";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      selCerList: [],
      userInfoSocial: provinceAndCityData,
      expectCity: [],
      cerList: [],
      jobList: [],
      selJobList: [],
      editWorkInfo: {
        // cerList: [
        //   {
        //     cer: "",
        //   },
        // ],
      },
    };
  },
  methods: {
    ...mapMutations(["changetalentNoLoginInfo"]),
    // 证书下拉
    getCerList() {
      this.$http.post("index/certificate/certificate").then((res) => {
        this.selCerList = res.data.resultData;
      });
    },
    // 职位下拉
    getJobList() {
      this.$http.post("index/certificate/postion").then((res) => {
        this.selJobList = res.data.resultData;
      });
    },
    setWorkInfoCity() {
      // let loc = "";
      // for (let i = 0; i < this.expectCity.length; i++) {
      //   loc += CodeToText[this.expectCity[i]];
      // }
      // let city = loc.replace("市辖区", "-");
      // if (city.charAt(city.length - 1) == "-") {
      //   city.substr(0, city.length - 1);
      // }
      console.log(this.expectCity);
      console.log(
        CodeToText[this.expectCity[0]],
        CodeToText[this.expectCity[1]]
      );
      this.editWorkInfo.week_province = CodeToText[this.expectCity[0]];
      this.editWorkInfo.week_city = CodeToText[this.expectCity[1]];
    },
    editWorkInfoPost() {
      if (this.workHidden) {
        this.editWorkInfo.hidden = 1;
      } else {
        this.editWorkInfo.hidden = 0;
      }
      if (this.workFace) {
        this.editWorkInfo.negotiable = 1;
      } else {
        this.editWorkInfo.negotiable = 0;
      }
      if (this.cerList.length === 3) {
        this.editWorkInfo.certificate = this.cerList[0];
        this.editWorkInfo.major = this.cerList[1];
        this.editWorkInfo.leve = this.cerList[2];
      } else if (this.cerList.length === 2) {
        this.editWorkInfo.certificate = this.cerList[0];
        this.editWorkInfo.major = this.cerList[1];
        this.editWorkInfo.leve = "";
      }
      this.editWorkInfo.position_certificate = this.jobList[0];
      this.editWorkInfo.position_major = this.jobList[1];
      this.editWorkInfo.position_leve = "初级";
      this.$http.post("talent/index/publish", this.editWorkInfo).then((res) => {
        if (res.data.resultCode === 1) {
          this.changetalentNoLoginInfo({
            talentNoLoginInfo: this.editWorkInfo,
          });
          this.$message.success("发布成功，2秒后跳转个人首页！");
          setTimeout(() => {
            this.$router.push("/talentPage/index");
          }, 1400);
        }
      });
    },
  },
  mounted() {
    this.getCerList();
    this.getJobList();
  },
};
</script>
<style scoped>
.titlebg {
  width: 1200px;
  height: 64px;
  background: linear-gradient(270deg, #0062ff 0%, #5483ff 100%);
  box-shadow: 0px -1px 0px 0px #e8e8e8;
  border-radius: 4px 4px 0px 0px;
  text-align: center;
  line-height: 64px;
  font-size: 18px;
  color: #fff;
}
.firstResume {
  width: 800px;
  margin: 0 auto;
  padding: 24px 0;
}
.add-fi {
  position: absolute;
  font-size: 22px;
  top: 10px;
  right: -30px;
}
.remove-fi {
  position: absolute;
  font-size: 22px;
  top: 10px;
  right: -60px;
}
.add-fi:hover,
.remove-fi:hover {
  color: #2573f1;
}
</style>
